const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\Layout.tsx";import { Spin } from "antd";
import React from "react";
import styled from "styled-components";

import { useStore } from "../store";

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Content = styled.div`
  padding-top: calc(20px + var(--header-height));
  padding-bottom: 20px;

  position: relative;

  z-index: 1;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const Layout = React.memo(function Layout({ children }) {
  const loading = useStore(
    React.useCallback((state) => state.sitesLoading, [])
  );

  if (loading) {
    return (
      React.createElement(LoadingContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        , React.createElement(Spin, { size: "default", tip: "Die Anwendung lädt.."  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}} )
      )
    );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Content, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, children)
      )
    )
  );
});
