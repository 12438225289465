const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\DeploymentUploadModal.tsx";import React from "react";

import { Button, Form, message, Modal, Select, Upload } from "antd";
import { Icon } from "@opendash/icons";

import { useStore } from "../store";





export const DeploymentUpload = React.memo(function DeploymentUpload({
  siteId,
}) {
  const state = useStore();

  const [open, setOpen] = React.useState(false);
  const [branch, setBranch] = React.useState("main");

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Button, {
        onClick: () => {
          setOpen(true);
        },
        style: { float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      , "Neues Deployment hochladen"

      )
      , React.createElement(Modal, {
        title: "Deployment Upload" ,
        visible: open,
        onCancel: () => setOpen(false),
        okButtonProps: { style: { display: "none" } }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      
        , React.createElement(Form, { layout: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
          , React.createElement(Form.Item, { label: "Branch", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
            , React.createElement(Select, {
              value: branch,
              onChange: (v) => {
                setBranch(v);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
            
              , React.createElement(Select.Option, { value: "main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}, "main")
              , React.createElement(Select.Option, { value: "next", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, "next")
            )
          )
          , React.createElement(Form.Item, { label: "Archive (.zip)" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
            , React.createElement(Upload.Dragger, {
              name: "file",
              style: { width: "100%" },
              showUploadList: false,
              action: `${state.host}/api/sites/${siteId}/branch/${branch}/deploy`,
              onChange: (info) => {
                if (info.file.status === "done") {
                  message.info("Deployment wurde hochgeladen");
                  state.fetchSites();
                  setOpen(false);
                }
              },
              headers: {
                Authorization: "Bearer " + state.token,
              },
              multiple: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
            
              , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
                , React.createElement(Icon, { icon: "fa:upload", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}} )
                , React.createElement('div', { style: { marginTop: 8 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}, "Hier klicken oder Datei ablegen"

                )
              )
            )
          )
        )
      )
    )
  );
});
