const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\NotFoundRoute.tsx";import React from "react";

import { ErrorMessage } from "./ErrorMessage";

export const NotFoundRoute = React.memo(function NotFoundRoute() {
  return (
    React.createElement(ErrorMessage, {
      icon: "fa:exclamation-circle",
      title: "Seite nicht gefunden"  ,
      message: "Die Seite konnte nicht gefunden werden"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
    )
  );
});
