const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\index.tsx";import React from "react";
import ReactDOM from "react-dom";

import "antd/dist/antd.css";
import "./index.css";

import { App } from "./components/App";
import { registerIconPack } from "@opendash/icons";

main();

async function main() {
  //@ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  ReactDOM.render(
    React.createElement(React.StrictMode, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      , React.createElement(App, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} )
    ),
    document.getElementById("opendash")
  );
}
