import createStore from "zustand/vanilla";
import createHook from "zustand";
import { noCase } from "no-case";

import { message } from "antd";






















































export const store = createStore((set, get, api) => ({
  host: "",
  token: "",
  authorized: false,
  invalidHost: false,
  invalidToken: false,
  sites: [],
  sitesLoading: false,
  fetchApi: async (endpoint, method = "get", body = {}) => {
    const { host, token } = get();

    const response = await fetch(host + "/api/" + endpoint, {
      method,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: method === "post" ? JSON.stringify(body) : undefined,
    });

    if (response.status === 401) {
      const error = new Error();

      error.name = "Unauthorized";
      error.message = await response.json();

      throw error;
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return await response.json();
  },
  login: async (host, token) => {
    if (!(host.startsWith("http://") || host.startsWith("https://"))) {
      host = "//" + host;
    }

    set({ host, token });

    const { fetchApi, fetchSites } = get();

    try {
      const response = await fetchApi("status");

      if (response.ok === true) {
        set({ authorized: true });

        await fetchSites();
      } else {
        throw new Error("Unknown Error");
      }
    } catch (error) {
      const unauthorized = error.name === "Unauthorized";

      set({
        authorized: false,
        invalidHost: !unauthorized,
        invalidToken: unauthorized,
      });

      if (unauthorized) {
        message.error("Ungültiges Access Token");
      } else {
        message.error("Ungültiger Host");

        console.error(error);
      }
    }
  },
  fetchSites: async () => {
    const { fetchApi } = get();

    const sites = await fetchApi("sites");

    set({ sites });
  },
  upsertSite: async (id, config) => {
    const { fetchApi, fetchSites } = get();

    await fetchApi(`sites/${id}`, "post", config);

    message.success("Seite wurde gespeichert");

    await fetchSites();
  },
  deleteDeployment: async (siteId, deploymentId) => {
    const { fetchApi, fetchSites } = get();

    await fetchApi(`sites/${siteId}/deployments/${deploymentId}`, "delete");

    message.success("Seite wurde gelöscht");

    await fetchSites();
  },
  validateSiteName: (name) => {
    name = name || "";

    if (name.length < 4) {
      return "length";
    }

    if (noCase(name, { delimiter: "-" }) !== name) {
      return "case";
    }

    if (get().sites.some((site) => site.id === name)) {
      return "duplicate";
    }

    return null;
  },
}));

export const useStore = createHook(store);
