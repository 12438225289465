const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\App.tsx";import React from "react";

import { BrowserRouter as ReactRouter } from "react-router-dom";

import { Router } from "./Router";
import { Layout } from "./Layout";

export function App() {
  return (
    React.createElement(ReactRouter, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , React.createElement(Layout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
        , React.createElement(Router, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}} )
      )
    )
  );
}
