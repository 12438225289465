const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\SitesRoute.tsx";import React from "react";

import { Avatar, List, Typography } from "antd";

import { useStore } from "../store";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@opendash/icons";
import { SiteCreateModal } from "./SiteCreateModal";

export const SitesRoute = React.memo(function SitesRoute() {
  const sites = useStore(React.useCallback((state) => state.sites, []));
  const navigate = useNavigate();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(SiteCreateModal, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
      , React.createElement(Typography.Title, { level: 2, children: "Alle Seiten" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}} )

      , React.createElement(List, {
        itemLayout: "horizontal",
        dataSource: sites,
        renderItem: (site) => (
          React.createElement(Link, { to: `/sites/edit/${site.id}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
            , React.createElement(List.Item, { actions: [React.createElement(Icon, { icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )], __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
              , React.createElement(List.Item.Meta, {
                // avatar={<Avatar icon={<Icon icon={"fa:happy"} />} />}
                avatar: React.createElement(Avatar, { children: site.config.type, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} ),
                title: site.id,
                description: site.hosts.join(", "), __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
              )
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      )
    )
  );
});
