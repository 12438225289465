const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\SitesEditRoute.tsx";import React from "react";

import { Avatar, Divider, List, Typography } from "antd";
import { Icon } from "@opendash/icons";
import { Link, useParams } from "react-router-dom";

import { ErrorMessage } from "./ErrorMessage";
import { SiteConfigForm } from "./SiteConfigForm";
import { DeploymentUpload } from "./DeploymentUploadModal";

import { useStore } from "../store";

export const SitesEditRoute = React.memo(function SitesEditRoute() {
  const { siteId } = useParams();

  const site = useStore(
    React.useCallback(
      (state) => state.sites.find((site) => site.id === siteId),
      [siteId]
    )
  );

  const state = useStore();

  if (!site) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Seite nicht gefunden"  ,
        message: "Die Seite konnte nicht gefunden werden"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
      )
    );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Link, { to: "/sites", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}, "/sites")
      , React.createElement(Typography.Title, { level: 2, children: site.id, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}} )
      , React.createElement(Typography.Text, { type: "secondary", children: site.hosts.join(", "), __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 41}} )

      , React.createElement(Typography.Title, { level: 3, children: "Config", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}} )

      , React.createElement(SiteConfigForm, {
        key: site.id,
        config: site.config,
        onSubmit: (config) => {
          state.upsertSite(site.id, config);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )

      , React.createElement(DeploymentUpload, { siteId: site.id, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}} )

      , React.createElement(Typography.Title, { level: 3, children: "Deployments", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} )

      , React.createElement(List, {
        itemLayout: "horizontal",
        dataSource: site.deployments,
        renderItem: (deployment) => (
          React.createElement(List.Item, {
            actions: [
              React.createElement(Icon, {
                icon: "fa:trash",
                style: { cursor: "pointer" },
                onClick: () => {
                  state.deleteDeployment(site.id, deployment.id);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
              ),
              React.createElement(Icon, {
                icon: "fa:external-link",
                style: { cursor: "pointer" },
                onClick: () => {
                  window.open(deployment.urls[0], "_blank");
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
              ),
            ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
          
            , React.createElement(List.Item.Meta, {
              avatar: React.createElement(Avatar, { children: deployment.meta.branch, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}} ),
              title: deployment.hosts[0],
              description: new Date(deployment.meta.timestamp).toJSON(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
      )
    )
  );
});
