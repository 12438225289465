const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\Router.tsx";import React from "react";

import { useStore } from "../store";

import { Routes, Route, Navigate } from "react-router-dom";

import { SitesRoute } from "./SitesRoute";

import { NotFoundRoute } from "./NotFoundRoute";
import { Auth } from "./Auth";
import { SitesEditRoute } from "./SitesEditRoute";

export const Router = React.memo(function Router() {
  const authorized = useStore(
    React.useCallback((state) => state.authorized, [])
  );

  if (!authorized) {
    return React.createElement(Auth, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} );
  }

  return (
    React.createElement(Routes, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
      , React.createElement(Route, { path: "", element: React.createElement(Navigate, { to: "/sites", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
      , React.createElement(Route, { path: "sites", element: React.createElement(SitesRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} )
      , React.createElement(Route, { path: "sites/edit/:siteId", element: React.createElement(SitesEditRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
      , React.createElement(Route, { path: "*", element: React.createElement(NotFoundRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} )
    )
  );
});
