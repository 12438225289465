const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\SiteCreateModal.tsx";import React from "react";

import { Button, Form, Input, Modal, } from "antd";



import { useStore } from "../store";
import { useNavigate } from "react-router-dom";





export const SiteCreateModal = React.memo(function SiteCreateModal() {
  const state = useStore();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState



({
    value: "",
  });

  // React.useEffect(() => {
  //   if (open) {
  //   }
  // }, [open]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Button, {
        onClick: () => {
          setOpen(true);
          setName({ value: "" });
        },
        style: { float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      , "Neue Seite erstellen"

      )
      , React.createElement(Modal, {
        title: "Site Creation" ,
        visible: open,
        onCancel: () => setOpen(false),
        onOk: () => {
          state
            .upsertSite(name.value, {
              type: "static",
              hosts: [],
              spa: false,
              fileServerBrowser: false,
              letsencrypt: false,
            })
            .then(() => {
              navigate(`/sites/edit/${name.value}`);
            });
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      
        , React.createElement(Form, { layout: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
          , React.createElement(Form.Item, {
            label: "Name",
            validateStatus: name.validateStatus,
            help: name.hint, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
          
            , React.createElement(Input, {
              value: name.value,
              onChange: (e) => {
                const value = e.target.value;
                const validation = state.validateSiteName(value);

                console.log("change", value, validation);

                setName({
                  value,
                  validateStatus: validation ? "error" : "success",
                  hint:
                    validation === "length"
                      ? "Der Name muss mindestens 4 Buchstaben haben"
                      : validation === "case"
                      ? "Der Name muss im Kebab-Case sein (lowercase + '-')"
                      : validation === "duplicate"
                      ? "Der Name ist bereits vergeben"
                      : undefined,
                });
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
            )
          )
        )
      )
    )
  );
});
