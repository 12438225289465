const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\Auth.tsx";import React from "react";

import { Button, Form, Input } from "antd";
import { useStore } from "../store";

export const Auth = React.memo(function Auth({ children }) {
  const login = useStore(React.useCallback((state) => state.login, []));

  const onSubmit = React.useCallback(
    ({ username, password }) => {
      console.log(username, password);
      login(username, password);
    },
    [login]
  );

  return (
    React.createElement(Form, { name: "auth", layout: "vertical", initialValues: {}, onFinish: onSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      , React.createElement(Form.Item, {
        label: "Host",
        name: "username",
        rules: [{ required: true, message: "Bitte Host angeben." }], __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      
        , React.createElement(Input, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
      )

      , React.createElement(Form.Item, {
        label: "Access Token" ,
        name: "password",
        rules: [{ required: true, message: "Bitte Access Token angeben." }], __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
      
        , React.createElement(Input.Password, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} )
      )

      , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
        , React.createElement(Button, { type: "primary", htmlType: "submit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}, "Anmelden"

        )
      )
    )
  );
});
