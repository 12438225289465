const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\openinc-http-web\\app\\js\\components\\SiteConfigForm.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";

import { Button, Form, Input, Select, Switch } from "antd";
import { Icon } from "@opendash/icons";








export const SiteConfigForm = React.memo(function SiteConfigForm({
  config,
  onSubmit,
}) {
  const [form] = Form.useForm();

  const [type, setType] = React.useState(_optionalChain([config, 'optionalAccess', _ => _.type]) || "static");

  return (
    React.createElement(Form, {
      form: form,
      name: "Site.config",
      // layout="vertical"
      initialValues: config,
      autoComplete: "off",
      onFinish: (config) => {
        onSubmit(config);
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
    
      , React.createElement(Form.Item, {
        label: "Type",
        name: "type",
        rules: [
          { required: true, message: "Bitte einen Seiten Typ auswählen." },
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
      
        , React.createElement(Select, {
          onChange: (value) => {
            setType(value );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        
          , React.createElement(Select.Option, { value: "proxy", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}, "Proxy")
          , React.createElement(Select.Option, { value: "static", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, "Static")
        )
      )

      , React.createElement(Form.List, { name: "hosts", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        , (fields, { add, remove }, { errors }) => (
          React.createElement(React.Fragment, null
            , fields.map((field, index) => (
              React.createElement(Form.Item, {
                label: `Host #${index + 1}`,
                required: false,
                key: field.key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
              
                , React.createElement(Form.Item, {
                  ...field,
                  validateTrigger: ["onChange", "onBlur"],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Bitte gültigen Host eingeben.",
                    },
                  ],
                  noStyle: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
                
                  , React.createElement(Input, {
                    placeholder: "Hier gültigen Hostname eintragen.."   ,
                    style: { width: "60%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
                  )
                )
                , fields.length > 0 ? (
                  React.createElement(Icon, {
                    icon: "fa:minus-circle",
                    style: {
                      marginLeft: 10,
                      cursor: "pointer",
                    },
                    onClick: () => remove(field.name), __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
                  )
                ) : null
              )
            ))

            , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
              , React.createElement(Button, {
                type: "dashed",
                onClick: () => add(),
                style: { width: "60%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
              , "Host hinzufügen"

              )
              , React.createElement(Form.ErrorList, { errors: errors, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}} )
            )
          )
        )
      )

      , React.createElement(Form.Item, {
        label: "Letsencrypt / SSL"  ,
        name: "letsencrypt",
        valuePropName: "checked",
        rules: [
          {
            required: true,
            message: "Soll die Seite über HTTPS ausgeliefert werden?",
          },
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
      
        , React.createElement(Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 113}} )
      )

      , type === "static" && (
        React.createElement(Form.Item, {
          label: "Single Page Application"  ,
          name: "spa",
          valuePropName: "checked",
          rules: [
            {
              required: true,
              message: "Soll die Seite als SPA ausgeliefert werden?",
            },
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
        
          , React.createElement(Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 128}} )
        )
      )

      , type === "static" && (
        React.createElement(Form.Item, {
          label: "File Browser" ,
          name: "fileServerBrowser",
          valuePropName: "checked",
          rules: [
            {
              required: true,
              message: "Soll ein Filebrowser angezeigt werden?",
            },
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
        
          , React.createElement(Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 144}} )
        )
      )

      , type === "proxy" && (
        React.createElement(Form.Item, {
          label: "Proxy Ziel Server"  ,
          name: "proxyTarget",
          rules: [
            { required: true, message: "Bitte Proxy Ziel Server angeben." },
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
        
          , React.createElement(Input, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 156}} )
        )
      )

      , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
        , React.createElement(Button, { type: "primary", htmlType: "submit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}, "Speichern"

        )
      )
    )
  );
});
